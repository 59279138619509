


































































































import {Component, Vue } from 'vue-property-decorator';
import {Action } from 'vuex-class';
import {
  CaoComplianceDetailAction,
  CaoComplianceDetail as CaoComplianceDetailType,
  InlenerWerksoort,
  SalaryTableAction,
  WerksoortsAction,
} from '@/store/inleners/types';
import { SalaryTable } from '@/interfaces/salaryTable';
import {toReadableDateFormat} from '@/utilities/filters';
import SalaryTableDetail from '@/views/Inleners/SalaryTableDetail.vue';
import VButton from '@/components/VButton.vue';
import { GetInlenersCaoPdfAction } from '@/store/main/types';
import getInlenersCaoTemplate from '@/pdf-templates/inlenerscao';
import { ProfileImageAction } from '@/store/account/types';

@Component({
  filters: { toReadableDateFormat },
  components: { SalaryTableDetail, VButton },
})
export default class CaoComplianceDetail extends Vue {
  @Action('salaryTables', {namespace: 'inleners'}) private fetchSalaryTables!: SalaryTableAction;
  @Action('caoComplianceDetail', {namespace: 'inleners'}) private fetchCaoDetail!: CaoComplianceDetailAction;
  @Action('werksoorts', {namespace: 'inleners'}) private fetchWerksoorts!: WerksoortsAction;
  @Action('getInlenersCaoPdf', { namespace: 'main' })
  private getInlenersCaoPdf!: GetInlenersCaoPdfAction;
  @Action('profileImage', { namespace: 'account' }) private getProfileImage!: ProfileImageAction;

  private loaded = false;
  private makingPdf = false;
  private imageSrc = '';
  private werksoorts!: InlenerWerksoort[];
  private caoDetail!: CaoComplianceDetailType;
  private salaryTables!: SalaryTable[];
  private hourlyWages = false;

  private created() {
    this.getProfileImage().then((response) => {
      this.imageSrc = response;
    });
    const caoId = this.$route.params.id;
    Promise.all([this.fetchCaoDetail(caoId), this.fetchSalaryTables(caoId), this.fetchWerksoorts(caoId)])
    .then(([caoDetail, salaryTables, werksoorts]) => {
      this.caoDetail = caoDetail;
      this. salaryTables = salaryTables;
      this.werksoorts = werksoorts;
    })
    .finally(() => {
      this.loaded = true;
    });
  }

  private makePdf() {
    if (!this.caoDetail) { return; }
    this.makingPdf = true;
    const html = getInlenersCaoTemplate((this.$refs.details as any).innerHTML, this.imageSrc);
    this.getInlenersCaoPdf({ html, filename: this.caoDetail.readable_name_year })
    .then((link) => {
      link.click();
    })
    .finally(() => {
      this.makingPdf = false;
    });
  }
}

